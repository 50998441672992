enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  SET_MERCHANT_DATA = "setMerchantData",
  UPDATE_MERCHANT_DATA = "updateMerchantData",
  UPDATE_MERCHANT_STATUS = "updateMerchantStatus",
  GET_DEPARTMENTS = "getDepartmentsArray",
  GET_DEPARTMENTS_LIST = "getDepartmentsList",
  SET_CARD_POINT = "setCardPointData",
  SET_DEPARTMENT_SETTINGS = "setDepartmentSettings",
  LOAD_MERCHANT_DATA = "loadMerchant",
  SET_DEPARTMENT_STATUS = "setDepartmentStatus",
  SET_ALL_DEPARTMENT_STATUS = "setAllDepartmentStatus",
  LOAD_MERCHANTS = "loadMerchants",
  LOAD_MERCHANTS_DOMAINS = "loadMerchantsDomains",
  LOAD_MERCHANTS_LIST = "loadMerchantsList",
  DELETE_MERCHANT = "deleteMerchant",
  LOAD_USERS = "loadUsers",
  SEARCH_USERS = "searchUsers",
  LOAD_USER = "loadUser",
  LOAD_USER_ROLES = "loadUserRoles",
  UPDATE_USER = "updateUser",
  UPDATE_USER_PROFILE = "updateUserProfile",
  SAVE_USER = "saveUser",
  DELETE_USER = "deleteUser",
  LOAD_REPORTS_DEPARTMENTS = "loadReportsDepartments",
  GET_IFRAME_URLS = "getIFameUrls",
  GET_PERMIT_DEPARTMENTS = "getPermitDepartments",
  LOAD_CYCLE_LABELS = "loadCycleLabels",
  SET_DEPARTMENT_HIDE_FROM_USER = "setDepartmentHideFromUser",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_USER_TO_STORAGE = "setUserToStorage",
  SET_PASSWORD = "setPassword",
  SET_PREV_ROUTE = "setPreviousRoute",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  SET_SEARCH_STRING = "setSearchString",
  SET_SEARCH_TYPE = "setSearchType",
  SET_PARCELS_ARRAY = "setParcelsArray",
  SET_CYCLE_LABELS = "setCycleLabels",
  SET_PAYMENT_TYPES = "setPaymentTypes",
  ADD_TO_CART = "addToCart",
  SHOW_CART = "setCartState",
  SHOW_SECURE_MODAL = "setSecureModalState",
  DELETE_CART_ITEM = "deleteCartItem",
  CLEAR_CART = "clearCart",
  GET_ADMIN_SETTINGS = "getAdminSettings",
  SET_MERCHANT_ERROR = "setMerchantError",
  SET_MERCHANT = "setMerchant",
  SET_MERCHANT_INFO = "setMerchantInfo",
  SET_MERCHANTS = "setMerchants",
  SET_DOMAINS = "setDomains",
  SET_MERCHANTS_LIST = "setMerchantsList",
  SET_DEPARTMENTS = "setDepartments",
  SET_AVAILABLE_DEPARTMENTS = "setAvailableDepartments",
  SET_DEPARTMENT = "setDepartment",
  SET_DEPARTMENT_DATA = "setDepartmentData",
  CLEAN_MERCHANT_ERROR = "cleanMerchantError",
  SET_ROLL_TYPE = "setRollType",
  SET_MERCHANT_LOGO = "setMerchantLogoImg",
  SET_USERS = "setUsers",
  SET_SEARCHED_USERS = "setSearchedUsers",
  SET_PAGINATION_DATA = "setPaginationData",
  SET_SEARCH_PAGINATION_DATA = "setSearchPaginationData",
  SET_USER_ROLES = "setUserRoles",
  SET_USERS_ERROR = "setUsersError",
  CLEAN_USERS_ERROR = "cleanUsersError",
  SET_REPORTS = "setReports",
  SET_DEPARTMENT_ID = "setDepartmentId",
  SET_MERCHANT_NAME = "setMerchantName",
  SET_REPORTS_ERROR = "setReportsError",
  CLEAN_REPORTS_ERROR = "cleanReportsError",
  SET_REPORTS_DEPARTMENTS = "setActiveDepartments",
  SET_IFRAME_URLS = "setIFrameUrls",
  SET_PERMIT_DEPARTMENTS_ARRAY = "setPermitDepartmentsArray",
  SET_LOG_DATA = "setLogData",
}

enum Links {
  START_PAGE = "dashboard",
  SIGN_IN_PAGE = "sign-in",
  CARD_POINT_SITE = "isv-uat",
}
enum SourceTypes {
  SOURCE_TYPE_TYLER_API = 1,
  SOURCE_TYPE_DATA_IMPORT = 2,
  SOURCE_TYPE_URL = 3,
  SOURCE_TYPE_BLIND_PAYMENT = 4,
}
enum ImportUrls {
  Clinton = "Clinton",
  Brewster = "Brewster",
  Brooksville = "Brooksville",
  Brunswick = "Brunswick",
  Galion = "Galion",
  EORWA = "EORWA",
  LouisvilleOhio = "LouisvilleOhio",
  Massillon = "Massillon",
  MiamiCounty = "MiamiCounty",
  PantherCreek = "PantherCreek",
  LeAx = "LeAx",
  Tiffin = "Tiffin",
  Chardon = "Chardon",
  Stow = "Stow",
  Kent = "Kent",
}

enum MerchantNotificationType {
  GENERAL = "GENERAL",
  BILLING = "BILLING",
}

export {
  Actions,
  Mutations,
  Links,
  SourceTypes,
  ImportUrls,
  MerchantNotificationType,
};
